/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  PostApiV5AccountAccountIdTaxReturnsZipPackageBody,
  Uuid
} from '.././schemas'
import postApiV5AccountAccountIdTaxReturnsZipPackageMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AccountAccountIdTaxReturnsZipPackageErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Submits a request to create a zip file of all tax return documents
 */
export const postApiV5AccountAccountIdTaxReturnsZipPackage = (
    accountId: Uuid,
    postApiV5AccountAccountIdTaxReturnsZipPackageBody: PostApiV5AccountAccountIdTaxReturnsZipPackageBody,
 ) => {
      
      
      return postApiV5AccountAccountIdTaxReturnsZipPackageMutator<void>(
      {url: `/api/v5/account/${accountId}/tax_returns/zip_package`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5AccountAccountIdTaxReturnsZipPackageBody
    },
      );
    }
  


export const getPostApiV5AccountAccountIdTaxReturnsZipPackageMutationOptions = <TError = PostApiV5AccountAccountIdTaxReturnsZipPackageErrorType<void | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>, TError,{accountId: Uuid;data: PostApiV5AccountAccountIdTaxReturnsZipPackageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>, TError,{accountId: Uuid;data: PostApiV5AccountAccountIdTaxReturnsZipPackageBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>, {accountId: Uuid;data: PostApiV5AccountAccountIdTaxReturnsZipPackageBody}> = (props) => {
          const {accountId,data} = props ?? {};

          return  postApiV5AccountAccountIdTaxReturnsZipPackage(accountId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AccountAccountIdTaxReturnsZipPackageMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>>
    export type PostApiV5AccountAccountIdTaxReturnsZipPackageMutationBody = PostApiV5AccountAccountIdTaxReturnsZipPackageBody
    export type PostApiV5AccountAccountIdTaxReturnsZipPackageMutationError = PostApiV5AccountAccountIdTaxReturnsZipPackageErrorType<void | ForbiddenErrorObject>

    /**
 * @summary Submits a request to create a zip file of all tax return documents
 */
export const usePostApiV5AccountAccountIdTaxReturnsZipPackage = <TError = PostApiV5AccountAccountIdTaxReturnsZipPackageErrorType<void | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>, TError,{accountId: Uuid;data: PostApiV5AccountAccountIdTaxReturnsZipPackageBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AccountAccountIdTaxReturnsZipPackage>>,
        TError,
        {accountId: Uuid;data: PostApiV5AccountAccountIdTaxReturnsZipPackageBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5AccountAccountIdTaxReturnsZipPackageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    