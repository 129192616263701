import { QueryClient } from '@tanstack/react-query';
import { workflowQueryDocument } from 'api/workflows';
import {
  CreateWorkflowItem2MutationVariables,
  CreateWorkflowSectionMutationVariables,
  DeleteWorkflowItemMutationVariables,
  DeleteWorkflowSectionMutationVariables,
  SaveWorkflowMutationVariables,
  UpdateWorkflowMutationVariables,
  WorkflowQueryQuery,
  WorkflowSectionFragment,
  WorkflowStatus,
  WorkflowItemCondition2Fragment,
  WorkflowItem2Fragment,
} from 'gql/graphql';
import { randomString } from 'utilities/utils';
import { workflowRequestQueryDocument } from 'api/workflow-requests';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';

export function getWorkflowItemWithIndexFromCache(
  queryClient: QueryClient,
  activeIndex: SectionItemTuple,
  workflowId: string,
): [WorkflowItem2Fragment | null, WorkflowItemCondition2Fragment['items']] {
  const [sectionIndex, itemIndex] = activeIndex;
  if (sectionIndex === null || itemIndex === null) {
    return [null, []];
  }
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  const parentItem =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items2?.[itemIndex];

  if (parentItem === undefined) {
    return [null, []];
  }

  const conds = (
    parentItem.conditions?.map((condition) => condition.items ?? []) ?? []
  ).flat();

  return [parentItem, conds];
}

export async function addWorkflowItemToCacheOptimistically(
  queryClient: QueryClient,
  variables: Required<CreateWorkflowItem2MutationVariables>,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) =>
          section.id === variables.workflowSectionId
            ? {
                ...section,
                items2: [
                  ...(section.items2 || []),
                  {
                    id: randomString(),
                    prompt: variables.prompt,
                    type: variables.workflowItemType,
                    updatedAt: new Date().toISOString(),
                    section: {
                      id: variables.workflowSectionId,
                    },
                  },
                ],
              }
            : section,
        ),
      },
    });
  }
}

export async function addWorkflowItemFromUpdateToCache(
  queryClient: QueryClient,
  item: {
    id: any;
    prompt?: string | null;
    hint?: string | null;
    type: string;
    updatedAt?: any | null;
    position?: number | null;
    section: {
      id: any;
    };
  },
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    // find if parent or child item is being updated

    const [sectionIndex, itemIndex, conditionalItemIndex] =
      findItemIndexWithSectionId(oldWorkflows, item.id, item.section.id);

    const parentItem =
      oldWorkflows?.workflow?.sections?.[sectionIndex]?.items2?.[itemIndex];

    if (conditionalItemIndex !== -1 && parentItem) {
      const childItem =
        parentItem.conditions?.[0]?.items?.[conditionalItemIndex]!;

      let sanitizedItem = { ...childItem, ...item };
      // a very silly hack to handle the empty prompt that comes back from the server if we send them blank
      if (!sanitizedItem?.prompt || sanitizedItem.prompt === 'Empty Prompt') {
        sanitizedItem = {
          prompt: '',
          ...sanitizedItem,
        };
      }
      const prntItemCondition = parentItem.conditions?.[0];
      if (!prntItemCondition) return;
      const conditions = prntItemCondition?.items ?? [];

      const item2: WorkflowItem2Fragment = {
        ...parentItem,
        conditions: [
          {
            ...prntItemCondition,
            items: conditions.map((ci) =>
              ci.id === item.id ? sanitizedItem : ci,
            ),
          },
        ],
      };
      await addWorkflowItemToCache(queryClient, item2, workflowId, replace);
    }
    await addWorkflowItemToCache(queryClient, item, workflowId, replace);
  }
}

function findItemIndexWithSectionId(
  oldWorkflows: WorkflowQueryQuery,
  workflowItemId: string,
  sectionId?: string,
) {
  const sectionIndex = oldWorkflows?.workflow?.sections?.findIndex(
    (section) => section.id === sectionId,
  );
  if (sectionIndex === -1 || sectionIndex === undefined) return [-1, -1, -1];

  let itemIndex = [sectionIndex, -1, -1];
  oldWorkflows?.workflow?.sections?.[sectionIndex]?.items2?.some((i, idx) => {
    if (i.id === workflowItemId) {
      itemIndex = [sectionIndex, idx, -1];
      return true;
    }
    return i.conditions?.[0]?.items?.some((ci, cidx) => {
      if (ci.id === workflowItemId) {
        itemIndex = [sectionIndex, idx, cidx];
        return true;
      }
      return false;
    });
  });
  return itemIndex;
}

export async function addWorkflowItemToCache(
  queryClient: QueryClient,
  workflowItem: WorkflowItem2Fragment,
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (section.id === workflowItem.section.id)
            return {
              ...section,
              items2: replace
                ? section.items2?.map((item) =>
                    item.id === workflowItem.id ? workflowItem : item,
                  )
                : [...(section.items2 || []), workflowItem],
            } as WorkflowSectionFragment;
          return section;
        }),
      },
    });
  }
}

export async function addWorkflowConditionToCache(
  queryClient: QueryClient,
  workflowItemId: string,
  workflowCondition: Omit<WorkflowItemCondition2Fragment, '__typename'>,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    let itemIndex: number | undefined = -1;
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (itemIndex !== undefined && itemIndex !== -1) return section;
          itemIndex = section.items2?.findIndex(
            (item) => item.id === workflowItemId,
          );

          return {
            ...section,
            items2:
              itemIndex === undefined || itemIndex === -1
                ? section.items2
                : ([
                    ...(section.items2?.slice(0, itemIndex) || []),
                    {
                      ...section.items2?.[itemIndex],
                      conditions: [workflowCondition],
                    },
                    ...(section.items2?.slice(itemIndex + 1) || []),
                  ] as WorkflowItem2Fragment[]),
          };
        }),
      },
    });
  }
}

export async function updateWorkflowConditionInCache(
  queryClient: QueryClient,
  workflowCondition: Omit<WorkflowItemCondition2Fragment, '__typename'>,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    let conditionFound = -1;
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (conditionFound !== -1) return section;

          return {
            ...section,
            items2:
              conditionFound === undefined || conditionFound === -1
                ? section.items2?.map((item) => {
                    conditionFound =
                      item.conditions?.findIndex(
                        (condition) => condition.id === workflowCondition.id,
                      ) ?? -1;
                    return {
                      ...item,
                      conditions:
                        conditionFound !== -1
                          ? [workflowCondition]
                          : item.conditions,
                    };
                  })
                : section.items2,
          };
        }),
      },
    });
  }
}

export async function addWorkflowSectionToCache(
  queryClient: QueryClient,
  workflowSection: WorkflowSectionFragment,
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: replace
          ? oldWorkflows.workflow?.sections?.map((section) => {
              if (section.id === workflowSection.id) {
                return workflowSection;
              }
              return section;
            })
          : [...(oldWorkflows?.workflow?.sections || []), workflowSection],
      },
    });
  }
}

export async function addWorkflowSectionsToCache(
  queryClient: QueryClient,
  workflowSections: WorkflowSectionFragment[],
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: replace
          ? workflowSections
          : [...(oldWorkflows?.workflow?.sections || []), ...workflowSections],
      },
    });
  }
}

export async function addWorkflowSectionToCacheOptimistically(
  queryClient: QueryClient,
  variables: CreateWorkflowSectionMutationVariables,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: [
          ...(oldWorkflows?.workflow?.sections || []),
          {
            id: randomString(),
            title: variables.title,
            items2: [],
            updatedAt: new Date().toISOString(),
          },
        ],
      },
    });
  }
}

export async function deleteWorkflowSectionFromCache(
  queryClient: QueryClient,
  variables: DeleteWorkflowSectionMutationVariables,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.filter(
          (section) => section.id !== variables.workflowSectionId,
        ),
      },
    });
  }
}

export async function deleteWorkflowItemFromCache(
  queryClient: QueryClient,
  variables: DeleteWorkflowItemMutationVariables,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows.workflow.sections?.map((section) => {
          if (!section.items2?.length) {
            return section;
          }

          // Filter out the target item from the main items array
          const filteredItems = section.items2
            .filter((item) => item.id !== variables.workflowItemId)
            .map((item) => ({
              ...item,
              // Update nested conditions
              conditions: item.conditions?.map((condition) => ({
                ...condition,
                items: condition.items?.filter(
                  (ci) => ci.id !== variables.workflowItemId,
                ),
              })),
            }));

          return {
            ...section,
            items2: filteredItems,
          };
        }),
      },
    });
  }
}

export const updateWorkflowStatusInCache = (
  queryClient: QueryClient,
  variables: UpdateWorkflowMutationVariables,
) => {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        status: variables.status as WorkflowStatus,
      },
    });
  }
};

export const saveWorkflowInCache = (
  queryClient: QueryClient,
  variables: SaveWorkflowMutationVariables,
) => {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.id },
  ];
  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        title: variables.title ? variables.title : oldWorkflows.workflow.title,
        description: variables.description
          ? variables.description
          : oldWorkflows.workflow.description,
        status: WorkflowStatus.Draft,
      },
    });
  }
};

export const invalidateWorkflowRequestsQuery = (
  id: string,
  queryClient: QueryClient,
) => {
  queryClient.invalidateQueries({
    queryKey: [
      (workflowRequestQueryDocument.definitions[0] as any).name.value,
      { id },
    ],
  });
};
