import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { usePostApiV5DocumentsSignedUrls } from 'api/v5/documents/documents';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import PSPDFKitViewer from 'common/PSPDFKit/PSPDFViewer';
import { CloseIcon } from 'common/Icons';

export type SignedUrlPDFDialogProps = {
  open: boolean;
  token: string;
  title: string;
  onClose: () => void;
  loadingCallback?: (loading: boolean) => void;
};

/**
 * Dialog to display a PDF viewer with token -> signedUrl logic.
 */
export const SignedUrlPDFDialog = ({
  open,
  token,
  title,
  onClose,
  loadingCallback,
}: SignedUrlPDFDialogProps) => {
  // state
  const [startFileLoading, setStartFileLoading] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');

  // API calls
  const { mutateAsync: getSignedUrl, isLoading } =
    usePostApiV5DocumentsSignedUrls({
      mutation: {
        cacheTime: 0,
      },
    });

  useEffect(() => {
    if (open) {
      if (token) {
        getSignedUrl(
          {
            data: {
              disposition: 'inline',
              retrieval_token: token || '',
            },
          },
          {
            onSuccess: (data) => {
              setSignedUrl(data.signed_url || '');
            },
            onError: (error) => {
              console.error('Error getting signed URL:', error);
              onClose();
              toast.error('Error retrieving document. Please try again.');
            },
            onSettled: () => {
              setStartFileLoading(false);
            },
          },
        );
      }
    }
  }, [getSignedUrl, onClose, open, startFileLoading, token]);

  // Callback to parent component
  useEffect(() => {
    if (loadingCallback) {
      loadingCallback(isLoading);
    }
  }, [isLoading, loadingCallback]);

  if (!token) {
    return null;
  }

  return (
    <Dialog
      open={open && !isLoading && !!signedUrl}
      fullScreen
      onClose={onClose}
      title={title}
      PaperProps={{
        sx: {
          width: '98vw',
          maxHeight: '98vh',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ height: '98%' }}>
        <PSPDFKitViewer fileName={title} url={signedUrl} />
      </DialogContent>
    </Dialog>
  );
};
