/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  GetApiV5AccountsAccountIdScheduleK1sParams,
  GetApiV5AccountsAccountIdTaxReturns200Item,
  ScheduleK1sArraySchema,
  TaxReturnSummary,
  Uuid
} from '.././schemas'
import getApiV5AccountsAccountIdScheduleK1sMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5AccountsAccountIdScheduleK1sErrorType } from '../../configOrvalAPIV5';
import getApiV5AccountsAccountIdTaxReturnsMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5AccountsAccountIdTaxReturnsErrorType } from '../../configOrvalAPIV5';
import getApiV5AccountsAccountIdTaxReturnsTaxReturnIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary List Schedule K1s
 */
export const getApiV5AccountsAccountIdScheduleK1s = (
    accountId: Uuid,
    params?: GetApiV5AccountsAccountIdScheduleK1sParams,
 signal?: AbortSignal
) => {
      
      
      return getApiV5AccountsAccountIdScheduleK1sMutator<ScheduleK1sArraySchema>(
      {url: `/api/v5/accounts/${accountId}/schedule_k1s`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiV5AccountsAccountIdScheduleK1sQueryKey = (accountId: Uuid,
    params?: GetApiV5AccountsAccountIdScheduleK1sParams,) => {
    return [`/api/v5/accounts/${accountId}/schedule_k1s`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV5AccountsAccountIdScheduleK1sQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>, TError = GetApiV5AccountsAccountIdScheduleK1sErrorType<void | ForbiddenErrorObject>>(accountId: Uuid,
    params?: GetApiV5AccountsAccountIdScheduleK1sParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5AccountsAccountIdScheduleK1sQueryKey(accountId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>> = ({ signal }) => getApiV5AccountsAccountIdScheduleK1s(accountId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5AccountsAccountIdScheduleK1sQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>>
export type GetApiV5AccountsAccountIdScheduleK1sQueryError = GetApiV5AccountsAccountIdScheduleK1sErrorType<void | ForbiddenErrorObject>


/**
 * @summary List Schedule K1s
 */

export function useGetApiV5AccountsAccountIdScheduleK1s<TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>, TError = GetApiV5AccountsAccountIdScheduleK1sErrorType<void | ForbiddenErrorObject>>(
 accountId: Uuid,
    params?: GetApiV5AccountsAccountIdScheduleK1sParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdScheduleK1s>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5AccountsAccountIdScheduleK1sQueryOptions(accountId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List Tax Returns
 */
export const getApiV5AccountsAccountIdTaxReturns = (
    accountId: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5AccountsAccountIdTaxReturnsMutator<GetApiV5AccountsAccountIdTaxReturns200Item[]>(
      {url: `/api/v5/accounts/${accountId}/tax_returns`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5AccountsAccountIdTaxReturnsQueryKey = (accountId: Uuid,) => {
    return [`/api/v5/accounts/${accountId}/tax_returns`] as const;
    }

    
export const getGetApiV5AccountsAccountIdTaxReturnsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>, TError = GetApiV5AccountsAccountIdTaxReturnsErrorType<void | ForbiddenErrorObject>>(accountId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5AccountsAccountIdTaxReturnsQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>> = ({ signal }) => getApiV5AccountsAccountIdTaxReturns(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5AccountsAccountIdTaxReturnsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>>
export type GetApiV5AccountsAccountIdTaxReturnsQueryError = GetApiV5AccountsAccountIdTaxReturnsErrorType<void | ForbiddenErrorObject>


/**
 * @summary List Tax Returns
 */

export function useGetApiV5AccountsAccountIdTaxReturns<TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>, TError = GetApiV5AccountsAccountIdTaxReturnsErrorType<void | ForbiddenErrorObject>>(
 accountId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5AccountsAccountIdTaxReturnsQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieve Tax Return
 */
export const getApiV5AccountsAccountIdTaxReturnsTaxReturnId = (
    accountId: Uuid,
    taxReturnId: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5AccountsAccountIdTaxReturnsTaxReturnIdMutator<TaxReturnSummary>(
      {url: `/api/v5/accounts/${accountId}/tax_returns/${taxReturnId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryKey = (accountId: Uuid,
    taxReturnId: Uuid,) => {
    return [`/api/v5/accounts/${accountId}/tax_returns/${taxReturnId}`] as const;
    }

    
export const getGetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>, TError = GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdErrorType<void | ForbiddenErrorObject>>(accountId: Uuid,
    taxReturnId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryKey(accountId,taxReturnId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>> = ({ signal }) => getApiV5AccountsAccountIdTaxReturnsTaxReturnId(accountId,taxReturnId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId && taxReturnId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>>
export type GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryError = GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdErrorType<void | ForbiddenErrorObject>


/**
 * @summary Retrieve Tax Return
 */

export function useGetApiV5AccountsAccountIdTaxReturnsTaxReturnId<TData = Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>, TError = GetApiV5AccountsAccountIdTaxReturnsTaxReturnIdErrorType<void | ForbiddenErrorObject>>(
 accountId: Uuid,
    taxReturnId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5AccountsAccountIdTaxReturnsTaxReturnId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5AccountsAccountIdTaxReturnsTaxReturnIdQueryOptions(accountId,taxReturnId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



