import { MOBILE_URL, REACT_ENVIRONMENT } from 'constants/constants';
import { matchRoutes } from 'react-router-dom-latest';
import extractHostnameFromUrl from 'utilities/extractHostnameFromUrl/extractHostnameFromUrl';
import { pathMap, pathMapRoutes } from './mobile-redirects';

/**
 * Get the configured mobile site redirect uri for a given location
 */
export const getMobileSiteRedirect = (
  {
    pathname,
    search,
  }: {
    pathname: string;
    search: string;
  },
  currentlyLoggedCpaUrl: string,
): [string | undefined, boolean] => {
  const redirectedFrom = new URLSearchParams(window.location.search).get(
    'redirectedFrom',
  );
  if (redirectedFrom !== null) {
    sessionStorage.setItem('redirectedFrom', redirectedFrom ?? '');
    // remove the redirectedFrom query param from the URL without reloading
    const url = new URL(window.location.href);
    url.searchParams.delete('redirectedFrom');
    window.history.replaceState({}, '', url.toString());
  }
  if ((!MOBILE_URL && !redirectedFrom) || pathname === '/') {
    return [undefined, false];
  }
  const [match] = matchRoutes(pathMapRoutes, pathname) || [];

  if (match) {
    const routePath = match.route.path || '';
    const pathConfig = pathMap[routePath];
    let { redirect: redirectPath } = pathConfig;
    const { bypassMobileCheck = false } = pathConfig;
    if (redirectPath) {
      // Resolve redirect path params
      Object.keys(match.params).forEach((param) => {
        const paramValue = match.params[param];
        if (paramValue && redirectPath) {
          redirectPath = redirectPath.replaceAll(
            `:${param}`,
            encodeURIComponent(paramValue),
          );
        }
      });

      // Resolve query parameters and attach company_name
      const company_name = extractHostnameFromUrl(currentlyLoggedCpaUrl);
      const companyParam = company_name
        ? `company_name=${encodeURIComponent(company_name)}`
        : '';
      let query = search ? `${search}&${companyParam}` : `?${companyParam}`;

      if (REACT_ENVIRONMENT !== 'production') {
        query += `&redirectedFrom=${encodeURIComponent(
          `${window.location.protocol}\\\\${window.location.host}`,
        )}`;
      }

      const host =
        process.env.REACT_APP_ENVIRONMENT !== 'production' &&
        sessionStorage.getItem('redirectedFrom')
          ? sessionStorage.getItem('redirectedFrom')
          : MOBILE_URL;
      const resolvedRedirect = `${host}${redirectPath}${query}`;
      return [resolvedRedirect, bypassMobileCheck];
    }
  }

  return [undefined, false];
};

/**
 * Determine whether the current device should be redirected to the mobile site
 */
export const getIsMobileRedirectDevice = (userAgent: string) =>
  // Redirect on presence of iphone/ipad/android in useragent
  ['iphone', 'ipad', 'android'].some((device) =>
    userAgent.toLowerCase().includes(device),
  );
