/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  PostApiV5DocumentsSignedUrls200,
  PostApiV5DocumentsSignedUrlsBody
} from '.././schemas'
import postApiV5DocumentsSignedUrlsMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5DocumentsSignedUrlsErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Returns a signed download url for a document
 */
export const postApiV5DocumentsSignedUrls = (
    postApiV5DocumentsSignedUrlsBody: PostApiV5DocumentsSignedUrlsBody,
 ) => {
      
      
      return postApiV5DocumentsSignedUrlsMutator<PostApiV5DocumentsSignedUrls200>(
      {url: `/api/v5/documents/signed_urls`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5DocumentsSignedUrlsBody
    },
      );
    }
  


export const getPostApiV5DocumentsSignedUrlsMutationOptions = <TError = PostApiV5DocumentsSignedUrlsErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>, TError,{data: PostApiV5DocumentsSignedUrlsBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>, TError,{data: PostApiV5DocumentsSignedUrlsBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>, {data: PostApiV5DocumentsSignedUrlsBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5DocumentsSignedUrls(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5DocumentsSignedUrlsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>>
    export type PostApiV5DocumentsSignedUrlsMutationBody = PostApiV5DocumentsSignedUrlsBody
    export type PostApiV5DocumentsSignedUrlsMutationError = PostApiV5DocumentsSignedUrlsErrorType<unknown>

    /**
 * @summary Returns a signed download url for a document
 */
export const usePostApiV5DocumentsSignedUrls = <TError = PostApiV5DocumentsSignedUrlsErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>, TError,{data: PostApiV5DocumentsSignedUrlsBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5DocumentsSignedUrls>>,
        TError,
        {data: PostApiV5DocumentsSignedUrlsBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5DocumentsSignedUrlsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    