/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ButtonProps,
  TextField,
  Typography,
  outlinedInputClasses,
  styled,
  useTheme,
} from '@mui/material';
import {
  useGetWorkflowById,
  useSaveAsTemplateWorkflowMutation,
  useUpdateWorkflow,
} from 'hooks/workflow-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Header } from 'ui/Header/Header';
import { WorkflowSection, WorkflowStatus } from 'gql/graphql';
import { DeleteOutline } from '@mui/icons-material';
import LoadingIndicator from 'common/LoadingIndicator';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-latest';
import {
  workflowTemplatesDocument,
  workflowsQueryDocument,
} from 'api/workflows';
import { useQueryClient } from '@tanstack/react-query';
import { AlertMessage, getFeatureGates } from 'utilities/utils';
import { DASHBOARD_ROUTE, TOAST_FAILURE } from 'constants/constants';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
} from 'components/Requests/requests.constants';
import { QuestionsBuilder } from './components/QuestionsBuilder';
import { useRequestBuilderContext } from './context/RequestsBuilderContext';
import { RequestsBuilderFooter } from './components/RequestsBuilderFooter/RequestsBuilderFooter';
import { RequestsBuilderTemplateFooter } from './components/RequestsBuilderTemplateFooter/RequestsBuilderFooterTemplate';
import { EditorStatus } from './components/EditorStatus/EditorStatus';
import { useFormSubmission } from './hooks/useFormSubmission';

const TIME_DELAY = 1000;

const EditableHeaderTextField = styled(TextField)((props) => ({
  [`& .${outlinedInputClasses.input}`]: {
    borderColor: props.error ? undefined : 'transparent',
  },
  [`& fieldset.${outlinedInputClasses.notchedOutline}`]: {
    borderColor: props.error ? undefined : 'transparent',
  },
}));

export interface BuilderHeaderForm {
  title: string;
  description: string;
}

export interface RequestsBuilderProps {
  onBack: () => void;
  workflowId: string;
}

export function RequestsBuilder({ onBack, workflowId }: RequestsBuilderProps) {
  const {
    isSubmitting,
    isDirty,
    setDirty,
    setWorkflowId,
    isSubmitted,
    setSubmitted,
    isTouched,
    clear,
    errors,
  } = useRequestBuilderContext();

  const { mutate: updateWorkflowStatusMutation, isLoading: updatingStatus } =
    useUpdateWorkflow();
  const { mutate: publishWorkflowTemplateMutation } =
    useSaveAsTemplateWorkflowMutation();
  const { data: workflowData, isInitialLoading: workflowLoading } =
    useGetWorkflowById(workflowId, { cacheTime: 0 });

  const formMethods = useForm<BuilderHeaderForm>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const { submitForm, latestFormValues } = useFormSubmission(
    workflowId,
    formMethods,
    setSubmitted,
  );

  const theme = useTheme();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const template = searchParams.get('template');

  const timeout = useRef<number>();
  const pWorkflowId = useRef('');

  // Cleanup timeout on unmount
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  useEffect(() => {
    if (pWorkflowId.current === workflowId) {
      return;
    }
    clear();
    setWorkflowId(workflowId);
    pWorkflowId.current = workflowId;
  }, [workflowId, setWorkflowId, clear]);

  const debounceWatch = useCallback(
    (callback: (values: BuilderHeaderForm) => void) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = window.setTimeout(() => {
        const submit = () => {
          if (
            formMethods.formState.dirtyFields.title ||
            formMethods.formState.dirtyFields.description
          ) {
            formMethods.handleSubmit(callback, (err) => console.error(err))();
          }
        };

        if (formMethods.formState.isSubmitting) {
          timeout.current = window.setTimeout(submit, TIME_DELAY);
          return;
        }
        submit();
      }, TIME_DELAY);
    },
    [formMethods],
  );

  useEffect(() => {
    const subscribe = formMethods.watch((values) => {
      latestFormValues.current = values as BuilderHeaderForm;
      debounceWatch(submitForm);
    });
    return () => subscribe.unsubscribe();
  }, [formMethods, debounceWatch, submitForm]);

  const handleOnPublish = useCallback(() => {
    publishWorkflowTemplateMutation(
      { id: workflowId },
      {
        onSettled: (response, e) => {
          if (e?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to save');
            return;
          }
          const queryKey = [
            (workflowTemplatesDocument.definitions[0] as any).name.value,
          ];
          if (response?.createWorkflowTemplateFromWorkflow?.workflowTemplate) {
            queryClient.invalidateQueries(queryKey);
          }
          onBack();
        },
      },
    );
  }, [publishWorkflowTemplateMutation, workflowId, queryClient, onBack]);

  useEffect(() => {
    if (
      !updatingStatus &&
      isSubmitted &&
      workflowData?.workflow?.status !== WorkflowStatus.Draft
    ) {
      updateWorkflowStatusMutation(
        {
          status: WorkflowStatus.Draft,
          workflowId,
        },
        {
          onSuccess: (response) => {
            if (response?.updateWorkflow?.errors?.length) {
              AlertMessage(TOAST_FAILURE, 'Failed to update workflow state');
            }
          },
        },
      );
    }
  }, [
    workflowData,
    workflowId,
    updateWorkflowStatusMutation,
    isSubmitted,
    updatingStatus,
  ]);

  const handleOnDelete = useCallback(() => {
    updateWorkflowStatusMutation(
      {
        status: WorkflowStatus.Deleted,
        workflowId,
      },
      {
        onSettled: (response, e) => {
          if (e?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to delete');
            return;
          }

          queryClient.invalidateQueries({
            queryKey: [
              (workflowsQueryDocument.definitions[0] as any).name.value,
            ],
          });
          onBack();
        },
      },
    );
  }, [updateWorkflowStatusMutation, workflowId, queryClient, onBack]);

  const handleOnClose = useCallback(() => {
    clear();
    if (location.state) {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  }, [clear, location.state, navigate]);

  const handleOnSend = useCallback(
    (requestId: string) => {
      clear();
      navigate(`${REQUESTS_PAGE_ROUTES.dispatch}/${requestId}`, {
        state: { from: IN_APP },
      });
    },
    [clear, navigate],
  );

  const handleOnBack = useCallback(() => {
    clear();
    onBack();
  }, [clear, onBack]);

  const featureGates = getFeatureGates();
  const menuItemsList = template
    ? []
    : [
        {
          icon: <DeleteOutline />,
          label: 'Delete',
          props: { onClick: handleOnDelete },
        },
      ];

  const buttonItemsList = [
    {
      label: 'Save as New Template',
      tooltip: !featureGates.custom_templates
        ? 'Upgrade to Pro Tier to create custom templates.'
        : undefined,
      props: {
        onClick: handleOnPublish,
        disabled: !featureGates.custom_templates || !isSubmitted,
        color: 'secondary' as ButtonProps['color'],
      },
    },
  ];

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      setDirty(true);
    }
    if (!formMethods.formState.isDirty) {
      const newValues = {
        title: workflowData?.workflow?.title || '',
        description: workflowData?.workflow?.description || '',
      };
      formMethods.reset(newValues);
      latestFormValues.current = newValues;
    }
  }, [workflowData, formMethods, setDirty]);

  if (workflowLoading && Boolean(workflowId)) {
    return <LoadingIndicator loading />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title={template ? 'Template Editor' : 'New Request'}
        onBack={handleOnBack}
        menuItems={menuItemsList}
        buttonItems={buttonItemsList}
      />
      <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
        <Box display="flex" justifyContent="end" mr={6} mt={2} minHeight={30}>
          <EditorStatus
            isTouched={isTouched}
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            isTemplate={Boolean(template)}
          />
        </Box>
        <Box
          borderBottom={`1px solid ${theme.palette.common.secondary[200]}`}
          display="flex"
          flexDirection="column"
          padding="0 2.3rem 1.6rem 2.3rem"
        >
          {errors.title && (
            <Typography id="requests-builder__title--error" color="error.main">
              {errors.title}
            </Typography>
          )}
          {errors.description && (
            <Typography
              id="requests-builder__description--error"
              color="error.main"
            >
              {errors.description}
            </Typography>
          )}
          <EditableHeaderTextField
            inputProps={{
              'aria-label': 'Title',
              maxLength: 150,
            }}
            hiddenLabel
            {...formMethods.register('title')}
            variant="outlined"
            defaultValue={workflowData?.workflow?.title}
            autoFocus
            error={Boolean(errors.title)}
            sx={{
              width: '35rem',
              '.MuiInputBase-input': {
                ...theme.typography.h1,
                fontWeight: 700,
                padding: 0,
              },
            }}
            aria-errormessage="requests-builder__title--error"
          />
          <EditableHeaderTextField
            inputProps={{
              'aria-label': 'Description',
              maxLength: 500,
            }}
            hiddenLabel
            multiline
            maxRows={3}
            {...formMethods.register('description')}
            placeholder="Add a description"
            defaultValue={workflowData?.workflow?.description}
            variant="outlined"
            error={Boolean(errors.description)}
            sx={{
              width: '35rem',
              '.MuiInputBase-root': {
                ...theme.typography.h4,
                padding: '1px',
                fontWeight: 700,
              },
            }}
            aria-errormessage="requests-builder__description--error"
          />
        </Box>
        <QuestionsBuilder
          initialSections={
            (workflowData?.workflow
              ?.sections as unknown[] as WorkflowSection[]) || undefined
          }
          footer={
            template ? (
              <RequestsBuilderTemplateFooter
                saveDisabled={!isSubmitted}
                workflow={workflowData?.workflow}
                onCancel={onBack}
                onClose={handleOnClose}
              />
            ) : (
              <RequestsBuilderFooter
                saveDisabled={!isSubmitted}
                sendDisabled={
                  workflowData?.workflow?.status !== WorkflowStatus.Draft &&
                  !isSubmitted
                }
                onClose={handleOnClose}
                onSend={handleOnSend}
                workflow={workflowData?.workflow}
              />
            )
          }
        />
      </Box>
    </Box>
  );
}
