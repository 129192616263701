import { Box, Button, Dialog } from '@mui/material';

interface ConfirmationDialogProps {
  children: React.ReactNode;
  cancelText?: string;
  confirmationText?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ConfirmationDialog({
  children,
  cancelText = 'Cancel',
  confirmationText = 'Yes',
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) {
  return (
    <Dialog open>
      <Box padding="30px">
        {children}
        <Box
          paddingTop="20px"
          display="flex"
          justifyContent="flex-start"
          gap="10px"
        >
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            {confirmationText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
