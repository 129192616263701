import { Typography, Skeleton, Divider, Stack, Button } from '@mui/material';
import { useGetApiV5AccountsAccountIdScheduleK1s } from 'api/v5/account/account';
import { ScheduleK1sArraySchema } from 'api/v5/schemas';
import { usePostApiV5AccountAccountIdTaxReturnsZipPackage } from 'api/v5/tax-return-zip-documents/tax-return-zip-documents';
import React, { useMemo } from 'react';
import { K1Members } from 'components/Accounts/elements/components/K1Members/K1Members';
import { toast } from 'react-toastify';

type K1PackagesProps = {
  accountUuid: string | undefined;
};

type K1sGroupedByYearData = {
  tax_year: number;
  members: ScheduleK1sArraySchema;
}[];

export const K1Packages = ({ accountUuid }: K1PackagesProps) => {
  const { data, isLoading, isError } = useGetApiV5AccountsAccountIdScheduleK1s(
    accountUuid || '',
    {},
    {
      query: { enabled: Boolean(accountUuid) },
    },
  );

  const K1sGroupedByYear = useMemo(
    () =>
      data &&
      data
        .reduce((acc, k1) => {
          const existingYear = acc.find(
            (year) => year.tax_year === k1.tax_year,
          );
          if (existingYear) {
            existingYear.members.push(k1);
          } else {
            acc.push({ tax_year: k1.tax_year, members: [k1] });
          }
          return acc;
        }, [] as K1sGroupedByYearData)
        ?.sort((a, b) => b.tax_year - a.tax_year),
    [data],
  );

  const { mutateAsync: postZipDownload } =
    usePostApiV5AccountAccountIdTaxReturnsZipPackage();

  const handleDownloadAll = (year: number) => {
    postZipDownload(
      {
        data: {
          tax_year: year,
        },
        accountId: accountUuid || '',
      },
      {
        onSuccess: () => {
          toast.success(
            'Preparing download. You will recieve a notification when the download is ready.',
            { autoClose: false },
          );
        },
        onError: (error) => {
          toast.error(
            "We weren't able to create the file you requested. Please try again.",
          );
          console.error(
            'K1packages.tsx handleDownloadAll error',
            accountUuid,
            year,
            error,
          );
        },
      },
    );
  };

  if (isError) {
    return <Typography mt={4}>Error loading K1 packages</Typography>;
  }

  if (isLoading) {
    return <Skeleton height={300} data-testid="skeleton" />;
  }

  if (!data?.length) {
    return null;
  }

  return (
    <Stack gap={1} mt={6}>
      <Typography variant="h3">K1 Packages</Typography>
      <Stack
        p={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      />
      <Stack p={1}>
        {K1sGroupedByYear?.map((k1) => (
          <React.Fragment key={k1.tax_year}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="h6"
                fontWeight="bold"
                component="span"
                py={4}
              >
                {k1.tax_year}
              </Typography>
              <Button onClick={() => handleDownloadAll(k1.tax_year)}>
                Download All
              </Button>
            </Stack>
            <Divider />
            {k1.members.map((member) => (
              <K1Members key={member.uuid} member={member} />
            ))}
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
