/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import {
  useCreateWorkflowItemCondition2,
  useDeleteWorkflowItemCondition,
  useCreateWorkflowConditionalItem,
  useUpdateWorkflowItemCondition2,
} from 'hooks/workflow-item-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { WorkflowItem2Fragment } from 'gql/graphql';
import { AlertMessage, getFeatureGates } from 'utilities/utils';
import { ConditionalQuestionEditor } from 'components/Requests/components/RequestsBuilder/components/ConditionalQuestionEditor/ConditionalQuestionEditor';
import { AddCircle } from '@mui/icons-material';
import { TOAST_FAILURE } from 'constants/constants';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { getWorkflowItemWithIdFromCache } from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import { addWorkflowItemToCache } from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';

import { useGetWorkflowById } from 'hooks/workflow-hooks';

export interface ConditionsEditorProps {
  activeUUID: string;
  number: number;
}

export function ConditionsEditor({
  number,
  activeUUID,
}: ConditionsEditorProps) {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { workflowId, isErrored, isSubmitting } = useRequestBuilderContext();

  const { mutate: createWorkflowItemMutation, isLoading } =
    useCreateWorkflowConditionalItem();
  const { mutate: createCondition } = useCreateWorkflowItemCondition2();
  const { mutate: deleteWorkflowItemConditionMutation } =
    useDeleteWorkflowItemCondition();
  const { mutate: updateCondition } = useUpdateWorkflowItemCondition2();

  const [parentItem, setParentItem] = useState<WorkflowItem2Fragment | null>(
    null,
  );
  const parentItemConditions = parentItem?.conditions || [];
  // Merge all child items from all conditions
  const childItems = parentItemConditions.flatMap(
    (condition) => condition.items || [],
  );
  // Get all unique match values
  const matchValues = [
    ...new Set(parentItemConditions.map((c) => c.matchValue)),
  ];

  // we do this to listen to changes in the cache without hitting the backend
  const { data: workflowData2 } = useGetWorkflowById(workflowId, {
    staleTime: Infinity,
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  // the condition is stored on the conditional item
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const [prntItem, condItems] = getWorkflowItemWithIdFromCache(
      queryClient,
      activeUUID,
      workflowId || '',
    );
    setParentItem(prntItem);

    // on first load, if there are items, enable the editor
    if (initialLoad && condItems?.length) {
      setEnabled(true);
      setInitialLoad(false);
    }
  }, [queryClient, workflowId, workflowData2, activeUUID, initialLoad]);

  const createConditionalItem = useCallback(
    (prntItemCondition = parentItemConditions[0]) => {
      if (!parentItem) {
        AlertMessage(TOAST_FAILURE, 'Failed to add condition');
        return;
      }
      if (!prntItemCondition) {
        return;
      }

      createWorkflowItemMutation(
        {
          conditionId: prntItemCondition.id,
          prompt: '',
          workflowItemType: WorkflowItemType.Boolean,
          workflowSectionId: parentItem?.section.id,
        },
        {
          onSuccess: (response) => {
            if (!response.createWorkflowItem2?.workflowItem) {
              return;
            }

            let sanitizedItem = response.createWorkflowItem2?.workflowItem;
            // a very silly hack to handle the empty prompt that comes back from the server if we send them blank
            if (
              !sanitizedItem.prompt ||
              sanitizedItem.prompt === 'Empty Prompt'
            ) {
              sanitizedItem = {
                prompt: '',
                ...sanitizedItem,
              };
            }
            const item: WorkflowItem2Fragment = {
              ...parentItem,
              conditions: [
                {
                  ...prntItemCondition,
                  items: [...(prntItemCondition.items ?? []), sanitizedItem],
                },
              ],
            };
            addWorkflowItemToCache(queryClient, item, workflowId, true);
          },
        },
      );
    },
    [
      createWorkflowItemMutation,
      parentItem,
      parentItemConditions,
      queryClient,
      workflowId,
    ],
  );

  const createInitialWorkflowItemAndCondition = useCallback(() => {
    if (!parentItem) {
      AlertMessage(TOAST_FAILURE, 'Failed to add condition');
      return;
    }
    createCondition(
      {
        workflowItemId: parentItem.id,
        matchValue: 'true',
        conditionType: 'equals',
      },
      {
        onSettled: (conditionResponse) => {
          createConditionalItem(
            conditionResponse?.createWorkflowItemCondition2
              ?.workflowItemCondition ?? undefined,
          );
        },
      },
    );
  }, [createCondition, createConditionalItem, parentItem]);

  useEffect(() => {
    if (enabled && parentItem && !childItems?.length) {
      createInitialWorkflowItemAndCondition();
    }
  }, [
    parentItem,
    childItems?.length,
    createInitialWorkflowItemAndCondition,
    workflowId,
    enabled,
  ]);

  const handleMatchValueChange = ({
    target: { value },
  }: SelectChangeEvent<string>) => {
    // Update all conditions to have the new match value
    parentItemConditions.forEach((condition) => {
      updateCondition({
        workflowItemConditionId: condition?.id,
        matchValue: value,
      });
    });
  };

  const handleEnabledChange = () => {
    if (enabled === true && childItems?.length) {
      // delete the new condition
      deleteWorkflowItemConditionMutation(
        {
          workflowItemConditionId: parentItemConditions[0]?.id,
        },
        {
          onSuccess: () => {
            if (!parentItem) {
              return;
            }
            const newParentItem = {
              ...parentItem,
              conditions: [],
            };
            addWorkflowItemToCache(
              queryClient,
              newParentItem,
              workflowId,
              true,
            );
          },
        },
      );
    }

    setEnabled((cEnabled) => !cEnabled);
  };

  const featureGate = getFeatureGates();
  const hasConditionalQuestionsFeature = featureGate?.conditional_questions;
  const conditionalFeatureTooltip =
    !hasConditionalQuestionsFeature &&
    'Upgrade to Pro Tier to create conditional questions.';

  return (
    <Stack gap="20px" paddingTop="20px">
      <Box>
        <TooltipStyled title={conditionalFeatureTooltip}>
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={handleEnabledChange}
                disabled={
                  !hasConditionalQuestionsFeature || !parentItem || isErrored
                }
              />
            }
            label="Conditions"
          />
        </TooltipStyled>
        <Typography color="text.secondary">
          Add conditions depending on the client answer.
        </Typography>
      </Box>
      {enabled && (
        <Stack bgcolor="common.white" gap="inherit" padding="20px">
          <Stack direction="row">
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.palette.success.main}
              borderRadius="4px 0px 0px 4px"
              sx={{ padding: '6px 12px' }}
            >
              <Typography
                component="label"
                htmlFor="matchValue"
                fontSize=".875rem"
                color={theme.palette.success.contrastText}
                fontWeight={700}
              >
                If
              </Typography>
            </Stack>
            <Select
              fullWidth
              value={matchValues.length ? matchValues[0] ?? 'true' : 'true'}
              onChange={handleMatchValueChange}
              variant="standard"
              inputProps={{
                id: 'matchValue',
                sx: {
                  border: `2px solid ${theme.palette.success.main}`,
                  borderLeft: 'none',
                  borderRadius: '0px 4px 4px 0px',
                },
              }}
              disabled={isSubmitting || isErrored || !parentItem}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </Stack>
          {matchValues.length > 0 &&
            childItems.map((ifItem, idx) => (
              <ConditionalQuestionEditor
                key={ifItem.id}
                label={`${number}.${idx + 1}`}
                item={ifItem}
              />
            ))}
          <Stack flexDirection="row" alignItems="center">
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
            <IconButton
              onClick={() => {
                createConditionalItem();
              }}
              disabled={
                isLoading ||
                isSubmitting ||
                isErrored ||
                !hasConditionalQuestionsFeature
              }
              color="primary"
              aria-label="Add conditional question"
            >
              <AddCircle />
            </IconButton>
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
