import React, { useCallback } from 'react';
import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { DMS_FILE_EXPORT, OAUTH_ACCESS_TOKENS } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import { BoxFile, ExportPickerProps } from './types';

import './picker.scss';

const fetchAccessToken = async () => {
  const response = await Axios.get(`${OAUTH_ACCESS_TOKENS}/box`);

  if (response.status === 200) {
    return response.data.access_token;
  }

  throw new Error('Failed to fetch access token');
};

const exportFiles = async (data: { app_key: string; remote_directory: number; document_ids: string[] }) => {
  const response = await Axios.post(DMS_FILE_EXPORT, data);
  return response.data;
};

export default function ExportPicker({ cancelWidget, selectedRecords }: ExportPickerProps) {
  const { data: accessToken } = useQuery({
    queryKey: ['accessToken'],
    queryFn: fetchAccessToken
  });

  const exportFilesMutation = useMutation(exportFiles, {
    onSuccess: (data) => {
      AlertMessage('success', data.message, 3000);
      cancelWidget();
    },
    onError: () => {
      AlertMessage('error', 'Upload failed!', 3000);
    },
  });

  const handleSelect = useCallback(
    (folders: BoxFile) => {
      const folder = folders[0];

      const data = {
        app_key: 'box',
        remote_directory: folder.id,
        document_ids: selectedRecords.map((a) => a.id),
      };

      exportFilesMutation.mutate(data);
    },
    [exportFilesMutation, selectedRecords],
  );

  const openPicker = useCallback(() => {
    // @ts-ignore
    const folderPicker = new Box.FolderPicker();
    folderPicker.addListener('cancel', cancelWidget);
    folderPicker.addListener('choose', handleSelect);
    folderPicker.show('0', accessToken, {
      container: '#box-picker',
      logoUrl: 'box',
      maxSelectable: 1,
    });
  }, [accessToken, cancelWidget, handleSelect]);

  if (!accessToken) {
    return null;
  }

  return (
    <Modal
      isOpen
      className="customModal customModal--center customModal--alert customModal--nopadding box-picker"
      onOpened={() => {
        openPicker();
      }}
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={cancelWidget}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalHeader>Select a Folder</ModalHeader>
      <ModalBody className="shiza">
        <div id="box-picker" />
      </ModalBody>
    </Modal>
  );
}
