/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ErrorObjectArray,
  ForbiddenErrorObject,
  PostApiV5FirmsWorkflowRequestsBulkDispatch200,
  PostApiV5FirmsWorkflowRequestsBulkDispatchBody,
  TaxReturnSummary,
  TaxReturnTodoArray
} from '.././schemas'
import getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosErrorType } from '../../configOrvalAPIV5';
import getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsWorkflowRequestsBulkDispatchMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType } from '../../configOrvalAPIV5';
import getApiV5WorkflowWorkflowRequestUuidToPdfMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5WorkflowWorkflowRequestUuidToPdfErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Tax Return Todo List for Workflow Request
 */
export const getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos = (
    workflowRequestUuid: string,
 signal?: AbortSignal
) => {
      
      
      return getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosMutator<TaxReturnTodoArray>(
      {url: `/api/v5/clients/workflow_requests/${workflowRequestUuid}/tax_return/todos`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryKey = (workflowRequestUuid: string,) => {
    return [`/api/v5/clients/workflow_requests/${workflowRequestUuid}/tax_return/todos`] as const;
    }

    
export const getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>, TError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosErrorType<void | ForbiddenErrorObject | ErrorObjectArray>>(workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryKey(workflowRequestUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>> = ({ signal }) => getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos(workflowRequestUuid, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowRequestUuid), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>>
export type GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosErrorType<void | ForbiddenErrorObject | ErrorObjectArray>


/**
 * @summary Tax Return Todo List for Workflow Request
 */

export function useGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos<TData = Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>, TError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosErrorType<void | ForbiddenErrorObject | ErrorObjectArray>>(
 workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodos>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnTodosQueryOptions(workflowRequestUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Tax Return for Workflow Request
 */
export const getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn = (
    workflowRequestUuid: string,
 signal?: AbortSignal
) => {
      
      
      return getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnMutator<TaxReturnSummary>(
      {url: `/api/v5/clients/workflow_requests/${workflowRequestUuid}/tax_return`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryKey = (workflowRequestUuid: string,) => {
    return [`/api/v5/clients/workflow_requests/${workflowRequestUuid}/tax_return`] as const;
    }

    
export const getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>, TError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnErrorType<ErrorObjectArray>>(workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryKey(workflowRequestUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>> = ({ signal }) => getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn(workflowRequestUuid, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowRequestUuid), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>>
export type GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnErrorType<ErrorObjectArray>


/**
 * @summary Tax Return for Workflow Request
 */

export function useGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn<TData = Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>, TError = GetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnErrorType<ErrorObjectArray>>(
 workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturn>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5ClientsWorkflowRequestsWorkflowRequestUuidTaxReturnQueryOptions(workflowRequestUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Dispatch one or many requests
 */
export const postApiV5FirmsWorkflowRequestsBulkDispatch = (
    postApiV5FirmsWorkflowRequestsBulkDispatchBody: PostApiV5FirmsWorkflowRequestsBulkDispatchBody,
 ) => {
      
      
      return postApiV5FirmsWorkflowRequestsBulkDispatchMutator<PostApiV5FirmsWorkflowRequestsBulkDispatch200>(
      {url: `/api/v5/firms/workflow_requests/bulk_dispatch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsWorkflowRequestsBulkDispatchBody
    },
      );
    }
  


export const getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsWorkflowRequestsBulkDispatch(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>>
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationBody = PostApiV5FirmsWorkflowRequestsBulkDispatchBody
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>

    /**
 * @summary Dispatch one or many requests
 */
export const usePostApiV5FirmsWorkflowRequestsBulkDispatch = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>,
        TError,
        {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary PDF for Workflow Request
 */
export const getApiV5WorkflowWorkflowRequestUuidToPdf = (
    workflowRequestUuid: string,
 signal?: AbortSignal
) => {
      
      
      return getApiV5WorkflowWorkflowRequestUuidToPdfMutator<Blob>(
      {url: `/api/v5/workflow/${workflowRequestUuid}/to_pdf`, method: 'GET',
        responseType: 'blob', signal
    },
      );
    }
  

export const getGetApiV5WorkflowWorkflowRequestUuidToPdfQueryKey = (workflowRequestUuid: string,) => {
    return [`/api/v5/workflow/${workflowRequestUuid}/to_pdf`] as const;
    }

    
export const getGetApiV5WorkflowWorkflowRequestUuidToPdfQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>, TError = GetApiV5WorkflowWorkflowRequestUuidToPdfErrorType<ErrorObjectArray>>(workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5WorkflowWorkflowRequestUuidToPdfQueryKey(workflowRequestUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>> = ({ signal }) => getApiV5WorkflowWorkflowRequestUuidToPdf(workflowRequestUuid, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowRequestUuid), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5WorkflowWorkflowRequestUuidToPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>>
export type GetApiV5WorkflowWorkflowRequestUuidToPdfQueryError = GetApiV5WorkflowWorkflowRequestUuidToPdfErrorType<ErrorObjectArray>


/**
 * @summary PDF for Workflow Request
 */

export function useGetApiV5WorkflowWorkflowRequestUuidToPdf<TData = Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>, TError = GetApiV5WorkflowWorkflowRequestUuidToPdfErrorType<ErrorObjectArray>>(
 workflowRequestUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5WorkflowWorkflowRequestUuidToPdf>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5WorkflowWorkflowRequestUuidToPdfQueryOptions(workflowRequestUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



