import { Button, Divider } from '@mui/material';
import { ScheduleK1Schema } from 'api/v5/schemas';
import { FileTypePdfIcon } from 'common/Icons';
import { SignedUrlPDFDialog } from 'components/SignedUrlPDFDialog/SignedUrlPDFDialog';
import { useState } from 'react';

type K1MembersProps = {
  member: ScheduleK1Schema;
};

export const K1Members = ({ member }: K1MembersProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<FileTypePdfIcon />}
        sx={(theme) => ({
          width: 'fit-content',
          padding: `${theme.spacing(4)} 0px`,
          marginLeft: `${theme.spacing(4)}`,
        })}
      >
        {member.member}
      </Button>
      <Divider />
      <SignedUrlPDFDialog
        open={open}
        token={member?.package?.retrieval_token || ''}
        title={member.member}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
