import { MOBILE_URL } from 'constants/constants';
import { useStorageData } from 'hooks/useStorageData';
import extractHostnameFromUrl from 'utilities/extractHostnameFromUrl/extractHostnameFromUrl';

export const useGenerateNewRequestsListPageUrl = (addCookie = false) => {
  const { storageData } = useStorageData();
  const currentlyLoggedCpaUrl = (storageData as { company_url: string })
    ?.company_url;
  const company_name = extractHostnameFromUrl(currentlyLoggedCpaUrl);

  const params = [];

  if (company_name) {
    params.push(`company_name=${encodeURIComponent(company_name)}`);
  }
  if (addCookie) {
    params.push('new-request-page=true');
  }
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    params.push(
      `redirectedFrom=${encodeURIComponent(
        `${window.location.protocol}\\\\${window.location.host}`,
      )}`,
    );
  }

  return `${
    process.env.REACT_APP_ENVIRONMENT !== 'production' &&
    sessionStorage.getItem('redirectedFrom')
      ? sessionStorage.getItem('redirectedFrom')
      : MOBILE_URL
  }/requests?${params.join('&')}`;
};
